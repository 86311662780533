import React, { useEffect, useState } from 'react'
import API from '../../../../../../../API/API'
import $ from 'jquery'
import './mappingList.css'
import SubmitMappings from '../SubmitMappings/SubmitMappings'
import EcommerceAttributeName from './EcommerceAttributeName/EcommerceAttributeName'
import CheckImg from '../../../../../../../../assets/images/check.png'
import CrossImg from '../../../../../../../../assets/images/close.png'


const MappingList = (props) => {
    const [sourceMethod, setSourceMethod] = useState([])
    const [targetMethod, setTargetMethod] = useState([])
    const [finalMappingList, setFinalMappingList] = useState()
    const [selectedMappings, setSelectedMappings] = useState([])

    const [defaultMappings, setDefaultMappings] = useState([])
    const [sourceMethods, setSourceMethods] = useState([])
    const [targetMethods, setTargetMethods] = useState([])
    const [sourceNotAllowed, setSourceNotAllowed] = useState([])
    const [validationMessage, setValidationMessage] = useState(null)

    const [filteredMethods, setFilteredMethods] = useState([])




    useEffect( () => {
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };
        API.get(`/Connection/${props.connectorId}/instance/${props.instancesId}/product-attribute`,{headers})
        .then((response) => {
            if(response.status === 200){
                setDefaultMappings(response.data.sourceMappedSchemaList)
                setSourceMethods(response.data && response.data.sourceMethods)
                setTargetMethods(response.data && response.data.targetMethods)

                const filteredList = response.data && response.data.targetMethods.filter(sourceItem => 
                    !response.data.sourceMappedSchemaList.some(targetItem => targetItem.ecommerceAttributeName === sourceItem.name)
                );

                setFilteredMethods(filteredList)
            }
        })
    },[])

    const selectSourceMethod = (event) =>{
        setValidationMessage(null)
        const notAllowed = event.currentTarget.getAttribute('notAllowed');
        const notAllowedArray = notAllowed && notAllowed.split(',').map(item => item.trim());
        setSourceNotAllowed(notAllowedArray)
        $(".stems ol li").removeClass("matched")
        $(".stems ol li").removeClass("selectedSource")
        $(".options ol li").removeClass("matched")
        $(".options ol li button").addClass('d-none')
        $(".options ol li").removeClass('d-none')
        $(".options ol li").removeClass('d-noneNew')

        $(event.target).addClass("matched")
        $(event.target).addClass("selectedSource")

        $("#newAttributeMappingTableNew tbody tr").each(function (){
            var selectedMethod = event.target.title
            var compareSelected = $(this).find('td:nth-child(6)').attr("headers")

            // console.log("Selected Source Method", selectedMethod)
            // console.log("Selected Target Method", compareSelected)

            if($(this).find('td:nth-child(1)').attr("headers") == event.target.title){
                $(".options ol li").each(function(){
                    if($(this).attr("title") === compareSelected){
                        $(this).addClass('d-none d-noneNew')
                    }
                })
            }
        })

        const erpDataType = event.currentTarget.getAttribute('erpDataType');
        const erpDataIsRequired = event.currentTarget.getAttribute('erpDataIsRequired');
        const erpDataSize = event.currentTarget.getAttribute('erpDataSize');
        const erpType = event.currentTarget.getAttribute('erptype');
        
        setSourceMethod(
            [{
                erpName:event.target.title,
                erpDataType:erpDataType,
                erpDataIsRequired:erpDataIsRequired,
                erpDataSize:erpDataSize,
                erpType:erpType,
            }]
        )
        setTargetMethod([])
    }

    

    const selectTargetMethod = (event) =>{
        const containsItem = sourceNotAllowed.includes(event.currentTarget.getAttribute('ecommdatatype'));
        setValidationMessage(null)
        if(containsItem){
            var formatSourceMethod = sourceMethod[0]
            setValidationMessage(`ERP field : ${formatSourceMethod.erpName} could not be mapped to Ecommerce field : ${event.target.title}`)
            //alert("Selected erp data type not matched with ecommerce data type")
        }else{
            $(event.target).addClass("matched")
            $(event.target).closest('li').addClass("matched")
            $(event.target).closest('li').find('button').removeClass('d-none')
            const ecommDataType = event.currentTarget.getAttribute('ecommdatatype');
            const ecommDataIsRequired = event.currentTarget.getAttribute('ecommdataisrequired');
            const ecommDataSize = event.currentTarget.getAttribute('ecommdatasize');
            const ecommType = event.currentTarget.getAttribute('ecommType');
            const ecommDefault = event.currentTarget.getAttribute('ecommdefault');

            var newFormatMethods = {
                ecommName:event.target.title,
                ecommDataType:ecommDataType,
                ecommDataIsRequired:ecommDataIsRequired,
                ecommDataSize:ecommDataSize,
                ecommType:ecommType,
                ecommDefault:ecommDefault
            }
            let selectedFinalMethods = [...targetMethod, newFormatMethods]
            setTargetMethod(selectedFinalMethods)
        }
    }

    const removeMap = (event) =>{
        $(event.target).removeClass("matched")
        $(event.target).closest("li").removeClass("matched")
        $(event.target).addClass("d-none")
        setTargetMethod(targetMethod.filter(v=>v!=event.target.name))
    }

    const addSelectedMethod= (event) =>{
        if($(".stems ol li").hasClass('selectedSource') && $(".options ol li").hasClass('matched')){
            var formatSourceMethod = sourceMethod[0]
            var mappingList = {
                erpName: formatSourceMethod.erpName,
                erpDataType: formatSourceMethod.erpDataType,
                erpDataSize: formatSourceMethod.erpDataSize,
                erpDataIsRequired: formatSourceMethod.erpDataIsRequired,
                erpType: formatSourceMethod.erpType,
                "ecommMethods": targetMethod, 
            }
            let selectedMappingList = [...selectedMappings, mappingList]

            const filteredList = targetMethods && targetMethods.filter(sourceItem => 
                !selectedMappingList.some(targetItem => targetItem.ecommMethods[0]?.ecommName === sourceItem.name)
            );

            setFilteredMethods(filteredList)

            setSelectedMappings(selectedMappingList)
            setSourceMethod()
            setTargetMethod([])
            $(".stems ol li").removeClass("matched")
            $(".stems ol li").removeClass("selectedSource")
            $(".options ol li").removeClass("matched")
            $(".options ol li button").addClass('d-none')
            console.log("Product Default", selectedMappingList)
        }else if($(".stems ol li").hasClass('selectedSource') === false){
            alert("please select ERP method")
        }else if($(".options ol li").hasClass('matched') === false){
            alert("please select eCommerce method")
        }

        
        
        


    }

    const delectMapping = (erpAttributeName, ecommerceAttributeName) => {
        const updatedMappings = defaultMappings.filter(
            item =>item.erpAttributeName !== erpAttributeName ||item.ecommerceAttributeName !== ecommerceAttributeName
        );
        setDefaultMappings(updatedMappings);
        const token = window.localStorage.getItem("accessToken");
        if (!token) {
            console.error("Access token not found!");
            return;
        }
        const headers = {
            "authorization": `Bearer ${token}`,
            "content-type": "application/json",
        };
        API.get(`/Connection/${props.connectorId}/instance/${props.instancesId}/product-attribute`, { headers })
        .then(apiResponse => {
            if (apiResponse.status === 200) {
                const filteredList = apiResponse.data?.targetMethods?.filter(
                    sourceItem => !updatedMappings.some(targetItem => targetItem.ecommerceAttributeName === sourceItem.name)
                );

                setFilteredMethods(filteredList);
                console.log("Filtered Methods:", filteredList);
            }
        })
        .catch(error => {
            console.error("Error fetching product attributes:", error);
        });
    };

    const delectMappingNew = (erpAttributeName, ecommerceAttributeName) => {
        const updatedMappings = selectedMappings.filter(
            item =>item.erpName !== erpAttributeName || item.ecommMethods[0]?.ecommName !== ecommerceAttributeName
        );

        setSelectedMappings(updatedMappings)


        // $(event.currentTarget).closest("tr").remove()
    }

    const autoSearchFieldERP = (event) => {
        $(".stems ol li").addClass('d-none')
        let givenValue = (event.target.value).toLowerCase();
        $(".stems ol li").each(function(){
            let ourSubstring = ($(this).attr('title')).toLowerCase();
            if(ourSubstring.includes(givenValue)) {
                $(this).removeClass('d-none')
            }
        })
    }

    const autoSearchFieldecomm = (event) => {
        $(".options ol li").addClass('d-none')
        let givenValue = (event.target.value).toLowerCase();
        $(".options ol li").each(function(){
            let ourSubstring = ($(this).attr('title')).toLowerCase();
            if(ourSubstring.includes(givenValue)) {
                $(this).removeClass('d-none')
            }
        })
    }

    const getUpdatedMappings = () =>{
        setSelectedMappings([])
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };
        API.get(`/Connection/${props.connectorId}/instance/${props.instancesId}/product-attribute`,{headers})
        .then((response) => {
            if(response.status === 200){
                setDefaultMappings(response.data.sourceMappedSchemaList)
                const filteredList = response.data && response.data.targetMethods.filter(sourceItem => 
                    !response.data.sourceMappedSchemaList.some(targetItem => targetItem.ecommerceAttributeName === sourceItem.name)
                );
                setFilteredMethods(filteredList)
            }
        })
    }


    return (
        <>
            <div class="question">
                <div class="stems">
                    <div className={'autoSearch'}>
                        <input 
                            type='text' 
                            placeholder='Search ERP Method' 
                            className="form-control" 
                            onChange={autoSearchFieldERP} 
                        />
                    </div>
                    <ol>
                        {
                            sourceMethods && sourceMethods.map((item, index) => 
                                <li 
                                    id={"s"+index} 
                                    title={item.name}
                                    erpdatatype={item.dataType}
                                    erpdataisrequired={item.isRequired ? "true" : "false"}
                                    erpdatasize={item.size}
                                    erptype={item.type}
                                    notAllowed={item.notAllowed}
                                    onClick={selectSourceMethod}
                                >
                                    {item.name}
                                </li>    
                            )
                        }
                    </ol>
                </div>
                <div class="options">
                    <div className={'autoSearch'}>
                        <input 
                            type='text' 
                            placeholder='Search eCommerce Method' 
                            className="form-control" 
                            onChange={autoSearchFieldecomm} 
                            id={'searchEcommMethodNew'}
                        />
                    </div>
                    <ol start="a">
                        {
                            filteredMethods && filteredMethods.map((item, index) => 
                                <li id={"o"+index} title={item.name}
                                    ecommdatatype={item.dataType}
                                    ecommdataisrequired={item.isRequired ? "true" : "false"}
                                    ecommdatasize={item.size}
                                    ecommtype={item.type}
                                    notAllowed={item.notAllowed}
                                    ecommdefault={item.default ? "true" : "false"}
                                >
                                    <div className='titleContainer' title={item.name} onClick={selectTargetMethod}
                                        ecommdatatype={item.dataType}
                                        ecommdataisrequired={item.isRequired ? "true" : "false"}
                                        ecommdatasize={item.size}
                                        ecommtype={item.type}
                                        notAllowed={item.notAllowed}
                                        ecommdefault={item.default ? "true" : "false"}
                                    >
                                        <span title={item.name} 
                                            ecommdatatype={item.dataType}
                                            ecommdataisrequired={item.isRequired ? "true" : "false"}
                                            ecommdatasize={item.size}
                                            ecommtype={item.type}
                                            notAllowed={item.notAllowed}
                                            ecommdefault={item.default ? "true" : "false"}
                                        >{item.name} </span>
                                    </div>
                                    <button className='cancelMapping d-none' name={item.name} onClick={removeMap}> X </button> 
                                    <div className='clearfix'></div>
                                </li>    
                            )
                        }
                    </ol>
                </div>
                <div className='actions'>
                    <button className='submitButton addSelectedAttributeMapping mb-2' onClick={addSelectedMethod}>
                        Add Selected Mappings
                    </button>
                </div>
                <div className='clearfix'></div>
            </div>
            <div className='validationMessage'> {validationMessage} </div>
            <div className='actions'>
                <SubmitMappings
                    connectorId={props.connectorId}
                    instancesId={props.instancesId}
                    getUpdatedMappings={getUpdatedMappings}
                />
                
            </div>
            <div id='sourcetotargettablechild' className='tableContainer'>
                <table id='newAttributeMappingTableNew' className='newMappingTable table table-striped table-bordered table-hover' width={"100%"}> 
                    <thead>
                        <tr className='table-primary'>
                            <th scope="col"> ERP Field </th>
                            {/* <th scope="col"> ERP DataType </th> */}
                            <th scope="col" align='center'> IsRequired </th>
                            {/* <th scope="col"> ERP Size </th>
                            <th scope="col"> ERP Type </th> */}
                            <th scope="col"> eCommerce Field </th>
                            {/* <th scope="col"> eCommerce DataType </th> */}
                            <th scope="col" align='center'> IsRequired </th>
                            {/* <th scope="col"> eCommerce Size </th>
                            <th scope="col"> eCommerce Type </th> */}
                            <th scope="col" align='center'> Action </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            selectedMappings && selectedMappings.map((item, i) =>
                                item.ecommMethods && item.ecommMethods.map((item1) => 
                                    <tr scope="row" className='dTable'>
                                        <td headers={item.erpName} data-name={item1.ecommName}> 
                                            <div className='toolTipCon' style={{ width: '100%'}} title={item.erpDataType}>
                                                <EcommerceAttributeName 
                                                    name={item.erpName} 
                                                    dataType={item.erpDataType}
                                                />
                                            </div>
                                        </td>
                                        <td headers={item.erpDataType} className='d-none'> {item.erpDataType} </td>
                                        <td headers={item.erpDataIsRequired} align='center'> 
                                            {item.erpDataIsRequired === "true" ? <img src={CheckImg} alt="" width={'25px'} /> : <img src={CrossImg} alt="" width={'25px'} />} 
                                        </td>
                                        <td headers={item.erpDataSize} className='d-none'> {item.erpDataSize} </td>
                                        <td headers={item.erpType} className='d-none'> {item.erpType} </td>
                                        <td headers={item1.ecommName} data-name={item1.ecommName}> 
                                            <EcommerceAttributeName 
                                                name={item1.ecommName} 
                                                dataType={item1.ecommDataType}
                                            />
                                        </td>
                                        <td headers={item1.ecommDataType} className='d-none'> {item1.ecommDataType} </td>
                                        <td headers={item1.ecommDataIsRequired} align='center'> 
                                            {item1.ecommDataIsRequired === "true" ? <img src={CheckImg} alt="" width={'25px'} /> : <img src={CrossImg} alt="" width={'25px'} />} 
                                        </td>
                                        <td headers={item1.ecommDataSize} className='d-none'> {item1.ecommDataSize} </td>
                                        <td headers={item1.ecommType} className='d-none'> {item1.ecommType} </td>
                                        <td headers={item1.ecommDefault} className='d-none'> {item1.ecommDefault} </td>
                                        <td align='center'> 
                                            <button 
                                                type="button" 
                                                className="deleteMapping" 
                                                //onClick={delectMappingNew}
                                                onClick={() => delectMappingNew(item.erpName, item1.ecommName)}
                                            >
                                                <i className="uil-trash-alt" onClick={() => delectMappingNew(item.erpName, item1.ecommName)}></i>
                                            </button>
                                        </td>
                                    </tr>
                                )
                                
                            )
                        }
                        {
                            defaultMappings && defaultMappings.map((item, i)=>
                                <tr>
                                    <td headers={item.erpAttributeName} data-name={item.erpAttributeName}> 
                                        <div className='toolTipCon' title={item.erpDataType}>
                                            <EcommerceAttributeName 
                                                name={item.erpAttributeName} 
                                                dataType={item.erpAttributeDataType}
                                            />
                                        </div>
                                    </td>
                                    <td headers={item.erpAttributeDataType} className='d-none'> {item.erpAttributeDataType} </td>
                                    <td headers={item.erpIsRequired ? "true" : "false"}  align='center'> 
                                        {item.erpIsRequired ? <img src={CheckImg} alt="" width={'25px'} /> : <img src={CrossImg} alt="" width={'25px'} />} 
                                    </td>
                                    <td headers={item.erpAttributeSize}  className='d-none'> {item.erpAttributeSize} </td>
                                    <td headers={item.erpAttributeType}  className='d-none'> {item.erpAttributeType} </td>
                                    <td headers={item.ecommerceAttributeName} data-name={item.erpAttributeName}> 
                                        <div className='toolTipCon'>
                                            <EcommerceAttributeName 
                                                name={item.ecommerceAttributeName} 
                                                dataType={item.ecommerceAttributeDataType}
                                            />
                                        </div>
                                    </td>
                                    <td headers={item.ecommerceAttributeDataType} className='d-none'> {item.ecommerceAttributeDataType} </td>
                                    <td headers={item.ecommerceIsRequired ? "true" : "false"} align='center'> 
                                        {item.ecommerceIsRequired ? <img src={CheckImg} alt="" width={'25px'} /> : <img src={CrossImg} alt="" width={'25px'} />} 
                                    </td>
                                    <td headers={item.ecommerceAttributeSize} className='d-none'> {item.ecommerceAttributeSize} </td>
                                    <td headers={item.ecommerceAttributeType} className='d-none'> {item.ecommerceAttributeType} </td>
                                    <td align='center'>
                                        {
                                            item.default ? 
                                            <>
                                                <button type="button" className="deleteMapping" disabled>
                                                    <i className="uil-trash-alt"></i>
                                                </button>
                                            </>
                                            :
                                            <>
                                                <button 
                                                    type="button" 
                                                    className="deleteMapping" 
                                                    onClick={() => delectMapping(item.erpAttributeName, item.ecommerceAttributeName)}
                                                >
                                                    <i 
                                                        className="uil-trash-alt" 
                                                        onClick={() => delectMapping(item.erpAttributeName, item.ecommerceAttributeName)}
                                                        
                                                    ></i>
                                                </button>
                                            </>
                                        }
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default MappingList
