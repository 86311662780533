import React, { useEffect, useState } from 'react'
import API from '../../../../../../../API/API'
import $ from 'jquery'
import './mappingList.css'
import SubmitMappings from '../SubmitMappings/SubmitMappings'
import EcommerceAttributeName from './EcommerceAttributeName/EcommerceAttributeName'
import CheckImg from '../../../../../../../../assets/images/check.png'
import CrossImg from '../../../../../../../../assets/images/close.png'

const MappingList = (props) => {
    const [sourceMethod, setSourceMethod] = useState()
    const [targetMethod, setTargetMethod] = useState([])
    const [finalMappingList, setFinalMappingList] = useState()
    const [selectedMappings, setSelectedMappings] = useState([])

    const [defaultMappings, setDefaultMappings] = useState()
    const [sourceMethods, setSourceMethods] = useState([])
    const [targetMethods, setTargetMethods] = useState([])
    const [sourceNotAllowed, setSourceNotAllowed] = useState([])
    const [validationMessage, setValidationMessage] = useState(null)
    
    const [filteredMethods, setFilteredMethods] = useState([])
    

    useEffect( () => {
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };
        API.get(`/Connection/${props.connectorId}/instance/${props.instancesId}/product-attribute`,{headers})
        .then((response) => {
            if(response.status === 200){
                setDefaultMappings(response.data.targetMappedSchemaList)
                setSourceMethods(response.data && response.data.sourceMethods)
                setTargetMethods(response.data && response.data.targetMethods)

                const filteredList = response.data && response.data.sourceMethods.filter(sourceItem => 
                    !response.data.targetMappedSchemaList.some(targetItem => targetItem.erpAttributeName === sourceItem.name)
                );

                setFilteredMethods(filteredList)
            }
        })
    },[])

    const selectSourceMethod = (event) =>{
        setValidationMessage(null)
        const notAllowed = event.currentTarget.getAttribute('notAllowed');
        const notAllowedArray = notAllowed && notAllowed.split(',').map(item => item.trim());
        setSourceNotAllowed(notAllowedArray)
        $(".stems ol li").removeClass("matched")
        $(".stems ol li").removeClass("selectedSource")
        $(".options ol li").removeClass("matched")
        $(".options ol li button").addClass('d-none')
        $(".options ol li").removeClass('d-none')
        $(".options ol li").removeClass('d-noneNew')

        $(event.target).addClass("matched")
        $(event.target).addClass("selectedSource")

        $("#newAttributeMappingTableNewTarget tbody tr").each(function (){
            var selectedMethod = event.target.title
            var compareSelected = $(this).find('td:nth-child(6)').attr("headers")
            if($(this).find('td:nth-child(1)').attr("headers") == event.target.title){
                $(".options ol li").each(function(){
                    if($(this).attr("title") === compareSelected){
                        $(this).addClass('d-none d-noneNew')
                    }
                })
            }
        })


        //setSourceMethod(event.target.title)
        const ecommDataType = event.currentTarget.getAttribute('ecommDataType');
        const ecommDataIsRequired = event.currentTarget.getAttribute('ecommDataIsRequired');
        const ecommDataSize = event.currentTarget.getAttribute('ecommDataSize');
        const ecommType = event.currentTarget.getAttribute('ecommtype');
        const ecommDefault = event.currentTarget.getAttribute('ecommdefault');


        setSourceMethod(
            [{
                ecommName:event.target.title,
                ecommDataType: ecommDataType,
                ecommDataIsRequired: ecommDataIsRequired,
                ecommDataSize: ecommDataSize,
                ecommType: ecommType,
                ecommDefault:ecommDefault
            }]
        )
        setTargetMethod([])
    }

    const selectTargetMethod = (event) =>{
        const containsItem = sourceNotAllowed && sourceNotAllowed.some(item => item.toLowerCase() === event.currentTarget.getAttribute('erpdatatype').toLowerCase());
        setValidationMessage(null)
        if(containsItem){
            var formatSourceMethod = sourceMethod[0]
            setValidationMessage(`Ecommerce field : ${formatSourceMethod.ecommName} could not be mapped to Erp field : ${event.target.title}`)
            //alert("Selected erp data type not matched with ecommerce data type")
        }else{
            $(event.target).addClass("matched")
            $(event.target).closest('li').addClass("matched")
            $(event.target).closest('li').find('button').removeClass('d-none')
            const erpDataType = event.currentTarget.getAttribute('erpdatatype');
            const erpDataIsRequired = event.currentTarget.getAttribute('erpdataisrequired');
            const erpDataSize = event.currentTarget.getAttribute('erpdatasize');
            const erpType = event.currentTarget.getAttribute('erpType');
            var newFormatMethods = {
                erpName:event.target.title,
                erpDataType:erpDataType,
                erpDataIsRequired:erpDataIsRequired,
                erpDataSize:erpDataSize,
                erpType:erpType
            }
            let selectedFinalMethods = [...targetMethod, newFormatMethods]
            setTargetMethod(selectedFinalMethods)
        }
    }

    const removeMap = (event) =>{
        $(event.target).removeClass("matched")
        $(event.target).closest("li").removeClass("matched")
        $(event.target).addClass("d-none")
        setTargetMethod(targetMethod.filter(v=>v!=event.target.name))
    }

    const addSelectedMethod= (event) =>{
        if($(".stems ol li").hasClass('selectedSource') && $(".options ol li").hasClass('matched')){
            var formatSourceMethod = sourceMethod[0]

            var mappingList = {
                ecommName: formatSourceMethod.ecommName,
                ecommDataType: formatSourceMethod.ecommDataType,
                ecommDataSize: formatSourceMethod.ecommDataSize,
                ecommDataIsRequired: formatSourceMethod.ecommDataIsRequired,
                ecommType: formatSourceMethod.ecommType,
                ecommDefault: formatSourceMethod.ecommDefault,
                "erpMethods": targetMethod, 
            }

            let selectedMappingList = [...selectedMappings, mappingList]
            const filteredList = sourceMethods && sourceMethods.filter(sourceItem => 
                !selectedMappingList.some(targetItem => targetItem.erpMethods[0]?.erpName === sourceItem.name)
            );
            setFilteredMethods(filteredList)
            setSelectedMappings(selectedMappingList)
            setSourceMethod()
            setTargetMethod([])
            $(".stems ol li").removeClass("matched")
            $(".stems ol li").removeClass("selectedSource")
            $(".options ol li").removeClass("matched")
            $(".options ol li button").addClass('d-none')
        }else if($(".stems ol li").hasClass('selectedSource') === false){
            alert("please select ERP method")
        }else if($(".options ol li").hasClass('matched') === false){
            alert("please select eCommerce method")
        }
    }

    const delectMapping = (ecommerceAttributeName, erpAttributeName) => {
        const updatedMappings = defaultMappings.filter(
            item =>item.ecommerceAttributeName !== ecommerceAttributeName ||item.erpAttributeName !== erpAttributeName
        );
        setDefaultMappings(updatedMappings);

        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };
        API.get(`/Connection/${props.connectorId}/instance/${props.instancesId}/product-attribute`,{headers})
        .then((response) => {
            if(response.status === 200){
                const filteredList = response.data && response.data.sourceMethods?.filter(
                    sourceItem => !updatedMappings.some(targetItem => targetItem.erpAttributeName === sourceItem.name)
                );
                setFilteredMethods(filteredList)
            }
        }).catch(error => {
            console.error("Error fetching product attributes:", error);
        });
    }

    const delectMappingNew = (ecommerceAttributeName, erpAttributeName) => {
        const updatedMappings = selectedMappings.filter(
            item =>item.ecommName !== ecommerceAttributeName || item.erpMethods[0]?.erpName !== erpAttributeName
        );
        setSelectedMappings(updatedMappings)
    }
    
    const autoSearchFieldERP = (event) => {
        $(".stems ol li").addClass('d-none')
        let givenValue = (event.target.value).toLowerCase();
        $(".stems ol li").each(function(){
            let ourSubstring = ($(this).attr('title')).toLowerCase();
            if(ourSubstring.includes(givenValue)) {
                $(this).removeClass('d-none')
            }
        })
    }

    const autoSearchFieldecomm = (event) => {
        $(".options ol li").addClass('d-none')
        let givenValue = (event.target.value).toLowerCase();
        $(".options ol li").each(function(){
            let ourSubstring = ($(this).attr('title')).toLowerCase();
            if(ourSubstring.includes(givenValue)) {
                $(this).removeClass('d-none')
            }
        })
    }

    const getUpdatedMappings = () =>{
        setSelectedMappings([])
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };
        API.get(`/Connection/${props.connectorId}/instance/${props.instancesId}/product-attribute`,{headers})
        .then((response) => {
            if(response.status === 200){
                setDefaultMappings(response.data.targetMappedSchemaList)
                setSourceMethods(response.data && response.data.sourceMethods)
                setTargetMethods(response.data && response.data.targetMethods)
                const filteredList = response.data && response.data.sourceMethods.filter(sourceItem => 
                    !response.data.targetMappedSchemaList.some(targetItem => targetItem.erpAttributeName === sourceItem.name)
                );
                setFilteredMethods(filteredList)
            }
        })
    }


    return (
        <>
            <div class="question">
                <div class="stems">
                    <div className={'autoSearch'}>
                        <input 
                            type='text' 
                            placeholder='Search eCommerce Field' 
                            className="form-control" 
                            onChange={autoSearchFieldERP} 
                        />
                    </div>
                    <ol>
                        {
                            targetMethods && targetMethods.map((item, index) => 
                                <li 
                                    id={"s"+index} 
                                    title={item.name} 
                                    ecommdatatype={item.dataType}
                                    ecommdataisrequired={item.isRequired ? "true" : "false"}
                                    ecommdatasize={item.size}
                                    ecommtype={item.type}
                                    notAllowed={item.notAllowed}
                                    ecommdefault={item.default ? "true" : "false"}
                                    onClick={selectSourceMethod}
                                >
                                    {item.name}
                                </li>    
                            )
                        }
                    </ol>
                </div>
                <div class="options">
                    <div className={'autoSearch'}>
                        <input 
                            type='text' 
                            placeholder='Search ERP Field' 
                            className="form-control" 
                            onChange={autoSearchFieldecomm} 
                        />
                    </div>
                    <ol start="a">
                        {
                            filteredMethods && filteredMethods.map((item, index) => 
                                <li id={"o"+index} title={item.name}
                                    erpdatatype={item.dataType}
                                    erpdataisrequired={item.isRequired ? "true" : "false"}
                                    erpdatasize={item.size}
                                    erptype={item.type}
                                    notAllowed={item.notAllowed}

                                >
                                    <div className='titleContainer' title={item.name} onClick={selectTargetMethod}
                                        erpdatatype={item.dataType}
                                        erpdataisrequired={item.isRequired ? "true" : "false"}
                                        erpdatasize={item.size}
                                        erptype={item.type}
                                        notAllowed={item.notAllowed}
                                    >
                                        <span title={item.name} 
                                            erpdatatype={item.dataType}
                                            erpdataisrequired={item.isRequired ? "true" : "false"}
                                            erpdatasize={item.size}
                                            erptype={item.type}
                                            notAllowed={item.notAllowed}
                                        >{item.name} </span>
                                    </div>
                                    <button className='cancelMapping d-none' name={item.name} onClick={removeMap}> X </button> 
                                    <div className='clearfix'></div>
                                </li>    
                            )
                        }
                    </ol>
                </div>
                <div className='actions'>
                    <button className='submitButton addSelectedAttributeMapping mb-2' onClick={addSelectedMethod}>
                        Add Selected Mappings
                    </button>
                </div>
                <div className='clearfix'></div>
            </div>
            <div className='validationMessage'> {validationMessage} </div>
            <div className='actions'>
                <SubmitMappings
                    connectorId={props.connectorId}
                    instancesId={props.instancesId}
                    getUpdatedMappings={getUpdatedMappings}
                />
            </div>
            <div className='tableContainer'>
                <table id='newAttributeMappingTableNewTarget' className='newMappingTable table table-striped table-bordered table-hover' width={"100%"}> 
                    <thead>
                        <tr className='table-primary'>
                            <th scope="col"> eCommerce Field </th>
                            {/* <th scope="col"> eCommerce DataType </th> */}
                            <th scope="col" align='center'> IsRequired </th>
                            {/* <th scope="col"> eCommerce Size </th> */}
                            {/* <th scope="col"> eCommerce Type </th> */}
                            <th scope="col"> ERP Field </th>
                            {/* <th scope="col"> ERP DataType </th> */}
                            <th scope="col" align='center'> IsRequired </th>
                            {/* <th scope="col"> ERP Size </th> */}
                            {/* <th scope="col"> ERP Type </th> */}
                            <th scope="col" align='center'> Action </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            selectedMappings && selectedMappings.map((item, i) =>
                                item.erpMethods && item.erpMethods.map((item1) => 
                                    <tr scope="row">
                                        <td headers={item.ecommName} data-name={item.ecommName}> 
                                            <div className='toolTipCon'>
                                                <EcommerceAttributeName 
                                                    name={item.ecommName} 
                                                    dataType={item.ecommDataType}
                                                />
                                            </div>
                                        </td>
                                        <td headers={item.ecommDataType} className='d-none'> {item.ecommDataType} </td>
                                        <td headers={item.ecommDataIsRequired} align='center'> 
                                            {item.ecommDataIsRequired === "true" ? <img src={CheckImg} alt="" width={'25px'} /> : <img src={CrossImg} alt="" width={'25px'} />} 
                                        </td>
                                        <td headers={item.ecommDataSize} className='d-none'> {item.ecommDataSize} </td>
                                        <td headers={item.ecommType} className='d-none'> {item.ecommType} </td>
                                        <td headers={item1.erpName}> 
                                            <div className='toolTipCon'>
                                                <EcommerceAttributeName 
                                                    name={item1.erpName} 
                                                    dataType={item1.erpDataType}
                                                />
                                            </div>
                                        </td>
                                        <td headers={item1.erpDataType} className='d-none'> {item1.erpDataType} </td>
                                        <td headers={item1.erpDataIsRequired} align='center'> 
                                            {item1.erpDataIsRequired === "true" ? <img src={CheckImg} alt="" width={'25px'} /> : <img src={CrossImg} alt="" width={'25px'} />} 
                                        </td>
                                        <td headers={item1.erpDataSize} className='d-none'> {item1.erpDataSize} </td>
                                        <td headers={item1.erpType} className='d-none'> {item1.erpType} </td>
                                        <td headers={item.ecommDefault} className='d-none'> {item.ecommDefault} </td>
                                        
                                        <td align='center'> 
                                            <button 
                                                type="button" 
                                                className="deleteMapping" 
                                                onClick={() => delectMappingNew(item.ecommName, item1.erpName)}
                                            >
                                                <i className="uil-trash-alt" onClick={() => delectMappingNew(item.ecommName, item1.erpName)}></i>
                                            </button>
                                        </td>
                                    </tr>
                                )
                                
                            )
                        }
                        {
                            defaultMappings && defaultMappings.map((item, i)=>
                                <tr>
                                    <td headers={item.ecommerceAttributeName}> 
                                        <div className='toolTipCon'>
                                            <EcommerceAttributeName 
                                                name={item.ecommerceAttributeName} 
                                                dataType={item.ecommerceAttributeDataType}
                                            />
                                        </div>
                                    </td>
                                    <td headers={item.ecommerceAttributeDataType} className='d-none'>{item.ecommerceAttributeDataType}</td>
                                    <td headers={item.ecommerceIsRequired ? "true" : "false"} align='center'>
                                        {item.ecommerceIsRequired ? <img src={CheckImg} alt="" width={'25px'} /> : <img src={CrossImg} alt="" width={'25px'} />} 
                                    </td>
                                    <td headers={item.ecommerceAttributeSize} className='d-none'>{item.ecommerceAttributeSize}</td>
                                    <td headers={item.ecommerceAttributeType} className='d-none'>{item.ecommerceAttributeType}</td>
                                    <td headers={item.erpAttributeName} data-name={item.erpAttributeName}> 
                                        <div className='toolTipCon'>
                                            <EcommerceAttributeName 
                                                name={item.erpAttributeName} 
                                                dataType={item.erpAttributeDataType}
                                            />
                                        </div>
                                        
                                    </td>
                                    <td headers={item.erpAttributeDataType} className='d-none'> {item.erpAttributeDataType} </td>
                                    <td headers={item.erpIsRequired ? "true":"false"} align='center'>
                                        {item.erpIsRequired ? <img src={CheckImg} alt="" width={'25px'} /> : <img src={CrossImg} alt="" width={'25px'} />} 
                                    </td>
                                    <td headers={item.erpAttributeSize} className='d-none'>{item.erpAttributeSize}</td>
                                    <td headers={item.erpAttributeType} className='d-none'>{item.erpAttributeType}</td>
                                    <td align='center'>
                                        {
                                            item.default ? 
                                            <>
                                                <button type="button" className="deleteMapping" disabled>
                                                    <i className="uil-trash-alt"></i>
                                                </button>
                                            </>
                                            :
                                            <>
                                                <button 
                                                    type="button" 
                                                    className="deleteMapping" 
                                                    onClick={() => delectMapping(item.ecommerceAttributeName, item.erpAttributeName)}
                                                >
                                                    <i 
                                                        className="uil-trash-alt" 
                                                        onClick={() => delectMapping(item.ecommerceAttributeName, item.erpAttributeName)}
                                                    ></i>
                                                </button>
                                            </>
                                        }
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
            
        </>
    )
}

export default MappingList
